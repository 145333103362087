import { render, staticRenderFns } from "./detailCard.vue?vue&type=template&id=f5386404&scoped=true&"
import script from "./detailCard.vue?vue&type=script&lang=js&"
export * from "./detailCard.vue?vue&type=script&lang=js&"
import style0 from "./detailCard.vue?vue&type=style&index=0&id=f5386404&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5386404",
  null
  
)

export default component.exports