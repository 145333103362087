<template>
  <div :style="[alignment === 'grid' ? { margin: '0px -100px' } : '']" :id="`detail-${content.objectid}`">
    <section class="detail" :style="[{ height: `${cardHeight}px` }]">
      <div class="detail-close-icon">
        <img src="@/assets/icons/close_with_shadow.svg" alt @click="closeDetail" />
      </div>
      <div
        v-if="content.category === 'MUSIC'"
        :style="[{ height: `${cardHeight - 40}px` }, { 'background-image': `url(${musicPoster})` }]"
        class="music-detail-bg"
      ></div>
      <div
        v-else-if="content.category === 'TVCHANEL' || content.category === 'ONLINE'"
        :style="[{ height: `${cardHeight - 105}px` }, { 'background-image': `url(${musicPoster})` }]"
        class="music-detail-bg"
      ></div>
      <div
        v-else
        :style="[{ 'background-image': `url(${poster})` }]"
        :class="[!readMore ? 'category-detail-bg' : 'category-detail-bg-blur']"
      ></div>
      <div
        class="music-poster"
        v-if="content.category === 'MUSIC' || content.category === 'TVCHANEL' || content.category === 'ONLINE'"
      >
        <img class="music-poster-img" :src="musicPoster" alt />
      </div>
      <div class="detail-linear-gradient"></div>

      <div class="detail-container container">
        <div class="detail-container-inner-detail" v-if="!readMore">
          <div class="detail-container-inner-detail-left">
            <div class="detail-container-inner-detail-left-topBack" v-if="activeComponent === 'Episodes'">
              <img class="back-icon" src="@/assets/icons/back.svg" alt="back-items" @click="getParentDetail" />
              <p class="back-title">{{ content.title }}</p>
            </div>
            <h4>
              {{
                checkEpisode
                  ? `S${episodeDetailArr.seasonnum} : E${episodeDetailArr.episodenum} ${episodeDetailArr.title}`
                  : content.title
              }}
            </h4>
            <div class="pb-y">
              <span class="sub-infos" v-if="content.objecttype === 'BUNDLE'">{{ content.objecttype }}</span>
              <span class="sub-infos" v-else-if="content.objecttype === 'CHANEL'">
                <img src="@/assets/icons/livetag.svg" alt />
              </span>
              <span class="sub-infos" v-else>{{ content.category }}</span>
              <span class="sub-infos">{{ content.genre }}</span>
              <span class="sub-infos">{{ content.contentlanguage[0] }}</span>
              <span class="sub-infos" v-if="content.productionyear">{{ content.productionyear }}</span>
              <span class="sub-infos pg-rating" v-if="content.pgrating">{{ content.pgrating }}</span>
              <!-- <span
            v-for="(item, index) in jhonor"
            :key="index"
            :class="[item === '16+' ? jhonorRating : '', jhonors]"
              >{{ item }}</span>-->
            </div>

            <div class="pb-y views-durations">
              <div class="duration-container" v-if="content.category === 'TVSHOW' || content.category === 'MUSIC'">
                <img src="@/assets/icons/season-light.svg" alt />
                <span class="info" v-if="content.category === 'TVSHOW'"
                  >{{ content.seasoncount }} {{ content.seasoncount > 1 ? "Seasons" : "Season" }}</span
                >
                <span class="info" v-else-if="content.category === 'MUSIC'"
                  >{{ content.trackcount }} {{ content.trackcount > 1 ? "Tracks" : "Track" }}</span
                >
              </div>
              <!-- <div class="duration-container" v-else-if="content.category === 'ONLINE'">
            <img src="@/assets/icons/livetag.svg" alt="items-count" />
              </div>-->
              <div
                class="duration-container"
                v-else-if="content.category === 'MOVIE' && content.objecttype !== 'BUNDLE'"
              >
                <img src="@/assets/icons/Duration.svg" alt="items-count" />
                <!-- <span class="info">5 ITEMS</span> -->
                <span class="info">{{
                  checkEpisode ? calcTimeFormat(episodeDetailArr.duration) : calcTimeFormat(content.duration)
                }}</span>
              </div>

              <div class="duration-container" v-else-if="content.objecttype === 'BUNDLE'">
                <img src="@/assets/icons/season-light.svg" alt />
                <span class="info">{{ content.itemlist.length }} Items</span>
              </div>
              <!-- <div class="view-container">
                <img src="@/assets/icons/ViewEye.svg" alt="views-count" />
                <span class="info">2K VIEWS</span>
              </div>-->
            </div>

            <div class="pb-y" v-if="content.details">
              <p class="cast-info">
                <span class="para-heading">{{ $t("starring") }}:</span>
                <span class="para-names" v-for="(item, index) in castData" :key="index">{{ item }}</span>
              </p>
              <p class="cast-info">
                <span class="para-heading">{{ $t("director") }}:</span>
                <span class="para-names" v-for="(item, index) in crewData" :key="index">{{ item }}</span>
              </p>
            </div>

            <div
              class="description pb-y"
              v-if="checkEpisode ? episodeDetailArr.shortdescription : content.shortdescription"
            >
              <span class="cast-info">
                {{ checkEpisode ? episodeDetailArr.shortdescription : content.shortdescription }}
                <span>...</span>
              </span>
              <span
                class="readmore"
                :class="readMoreColor"
                @click="readMore = true"
                v-if="checkEpisode ? episodeDetailArr.longdescription : content.longdescription"
                >{{ $t("Read More") }}</span
              >
            </div>

            <div class="icon-list pb-y">
              <!-- <img src="@/assets/icons/like-1.svg" alt="test" /> -->
              <div class="activity-btns">
                <LikeBtn
                  :content="checkEpisode ? episodeDetailArr : content"
                  :width="'25px'"
                  :height="'25px'"
                  @likeCount="contentLikes"
                  @dislikeCount="contentDislikes"
                  class="hover-change"
                ></LikeBtn>
                <div class="triangle"></div>
                <div class="tooltip">
                  <span>{{ $t("like") }}</span>
                </div>
                <!-- <p class="activity-counts">{{likeCountDisplay}}</p> -->
              </div>

              <!-- <img src="@/assets/icons/dislike-1.svg" alt="test" />
              <div class="activity-btns">
                <DislikeBtn
                  :content="checkEpisode ? episodeDetailArr : content"
                  :width="'25px'"
                  :height="'25px'"
                  @dislikeCount="contentDislikes"
                  @likeCount="contentLikes"
                  class="hover-change"
                ></DislikeBtn>
                <div class="triangle"></div>
                <div class="tooltip">
                  <span>{{ $t("dislike") }}</span>
                </div> -->
                <!-- <p class="activity-counts">{{dislikeCountDisplay}}</p> -->
              <!-- </div> -->
              <div class="activity-btns">
                <WatchListBtn
                  :content="checkEpisode ? episodeDetailArr : content"
                  :screen="screen"
                  :width="'25px'"
                  :height="'25px'"
                  class="hover-change"
                ></WatchListBtn>
                <div class="triangle"></div>
                <div class="tooltip">
                  <span>{{ $t("watchlist") }}</span>
                </div>
              </div>
              <!-- <img src="@/assets/icons/add.png" alt="test" @click="addToWatchlist" /> -->
              <div class="activity-btns">
                <detailShare :content="content" :width="'25px'" :height="'25px'" class="hover-change"></detailShare>
                <div class="triangle"></div>
                <div class="tooltip">
                  <span>{{ $t("share") }}</span>
                </div>
              </div>
            </div>

            <div v-if="isButtonEnable">
              <button
                class="button-primary play-now"
                :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
                @click="buttonHandler"
              >
                {{ $t(`${buttonStatus}`) }}
              </button>
            </div>
          </div>
          <div style="display:flex;justify-content: flex-end;">
            <div class="detail-container-inner-detail-tab-component" v-if="!readMore">
              <component :is="activeComponent" :content="content" @episodeDetail="episodeDetail"></component>
            </div>
          </div>
        </div>
        <div class="detail-container-read-more" v-if="readMore">
          <h4>{{ checkEpisode ? episodeDetailArr.title : content.title }}</h4>
          <div class="description">
            <img src="@/assets/icons/ClosePopup.svg" alt="close" @click="readMore = false" />
            <p>{{ checkEpisode ? episodeDetailArr.longdescription : content.longdescription }}</p>
          </div>
        </div>
        <div class="detail-container-tabs">
          <ul class="tablist">
            <li
              v-for="(item, index) in sections"
              :key="index"
              @click="selectTab(index)"
              :id="`tab-${item}`"
              :class="selectedSection === index ? activeTab : ''"
            >
              {{ $t(`${item}`) }}
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import Utility from "@/mixins/Utility.js";
import Common from "@/mixins/common.js";
import { _providerId, _projectName } from "@/provider-config.js";
export default {
  props: {
    content: {
      type: Object,
    },
    closePopup: {
      type: Function,
    },
    alignment: {
      type: String,
    },
    screen: {
      type: Object,
    },
  },
  data() {
    return {
      detail: "detail",
      musicDetail: "music-detail-bg",
      jhonors: "jhonors",
      jhonorRating: "rating",
      sections: [],
      activeTab: null,
      selectedSection: null,
      tabName: "",
      cardImage: null,
      readMore: false,
      cardHeight: null,
      activeComponent: null,
      togglePayment: false,
      likeCountDisplay: null,
      dislikeCountDisplay: null,
      castData: [],
      crewData: [],
      isButtonEnable: false,
      buttonStatus: null,
      filteredAvailabilities: [],
      pricemodel: [],
      subscriptions: [],
      availabilities: [],
      purchases: [],
      buttonStatus: "",
      episodeDetailArr: null,
      checkEpisode: false,
      buttonLoader: true,
      providerUniqueId: _providerId,
      readMoreColor: null,
      toolTipColor: null,
      triangleColor: null,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "availabilityList", "subscriptionList", "purchaseList"]),
    poster() {
      return this.getPoster();
    },
    musicPoster() {
      return this.getMusicPoster();
    },
    detailButton() {
      return this.buttonStatus;
    },
  },
  watch: {
    content() {
      this.castData = [];
      this.crewData = [];
      this.selectTab(0);
      this.getCastCrewData();

      //fetch detail card button status buy or watchnow.
      this.fetchButtonStatus();
    },
    subscriberid() {
      //fetch detail card button status buy or watchnow.
      this.fetchButtonStatus();
    },
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
      //fetch detail card button status buy or watchnow.
      this.fetchButtonStatus();
    },
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
      this.fetchButtonStatus();
    },
  },
  created() {
    //assigning availability list response.
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }
    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }

    //fetch detail card height and assign cardheight.
    this.fetchDetailCardHeight();

    //Listing all the detail card tabs based on objecttype.
    this.categoryTabs();

    //Fetching the cast and crew information based on content object.
    this.getCastCrewData();

    //check if the objecttype is SERIES.
    if (this.content.objecttype === "SERIES") {
      
    }

    // read More Color
    this.readMoreColor =
      _projectName === "NET5" || _projectName === "VLIVE"
        ? "readMore-color"
        : _projectName === "NAMMAFLIX"
        ? "readMore-color-white"
        : "";

    // active tab color
    this.activeTab =
      _projectName === "NET5" || _projectName === "VLIVE"
        ? "active-tab"
        : _projectName === "NAMMAFLIX"
        ? "active-tab-white"
        : "";

    // tooltip color
    this.toolTipColor =
      _projectName === "NET5" || _projectName === "VLIVE"
        ? "tooltip-color"
        : _projectName === "NAMMAFLIX"
        ? "tooltip-color-white"
        : "";

    // tooltip triangle Color change
    this.triangleColor =
      _projectName === "NET5" || _projectName === "VLIVE"
        ? "triangle-color"
        : _projectName === "NAMMAFLIX"
        ? "triangle-color-white"
        : "";
  },
  mounted() {
    //selecting the initial tab.
    this.selectTab(0);

    let elmnt = document.getElementById(`detail-${this.content.objectid}`);
    elmnt.scrollIntoView(true);

    //fetch detail card button status buy or watchnow.
    this.fetchButtonStatus();

    eventBus.$on("close-related-tab", () => {
      this.selectTab(0);
    });
  },
  methods: {
    fetchDetailCardHeight() {
      let bodyWidth = document.body.offsetWidth;
      let bodyHeight = Math.round(bodyWidth / (16 / 9));
      this.cardHeight = bodyHeight - 260;
    },
    selectTab(index) {
      this.selectedSection = index;
      if (index > -1) {
        this.activeComponent = this.sections[index];
        
        if (index !== 0) {
          this.readMore = false;
        }
      } else {
        this.activeComponent = "";
      }
    },
    fetchButtonStatus() {
      let content = this.content;
      //checking if content object has contentdetails.
      if (content.hasOwnProperty("contentdetails") && content.contentdetails.length > 0) {
        //Initially adding the button status as buy for the content.
        this.isButtonEnable = true;

        //filtering availabilities from availabitlity list.
        this.filteredAvailabilities = [];

        this.pricemodel = [];

        //fetching content details.
        let content_details = this.fetchContentDetails(content.contentdetails);

        let isContentPlayable = false;

        this.fetchAvailabilityId(content_details);
        if (
          (this.pricemodel.includes("PLAN") && this.pricemodel.includes("PAID")) ||
          this.pricemodel.includes("RENTAL")
        ) {
          this.buttonStatus = "SUBSCRIBE OR PURCHASE";
        } else if (this.pricemodel.includes("PLAN")) {
          this.buttonStatus = "SUBSCRIBE & WATCH";
        } else if (this.pricemodel.includes("FREE")) {
          this.buttonStatus = "LOGIN & WATCH";
        } else if (this.pricemodel.includes("PAID")) {
          this.buttonStatus = "PURCHASE & WATCH";
        }

        this.filteredAvailabilities.forEach((element) => {
          //check avalabilty belongs to subscribed list.
          if (element.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.forEach((subscription) => {
                let value = subscription.availabilityset.includes(element.availabilityid);
                if (value && !isContentPlayable) {
                  this.isButtonEnable = true;
                  this.buttonStatus = "PLAY NOW";
                  this.content.isDrmContent = true;
                  this.content.videoType = "Content";
                  this.content.playback_details = {
                    packageid: content_details.packageid,
                    availabilityid: element.availabilityid,
                    drmscheme: content_details.drmscheme[0],
                  };
                  isContentPlayable = true;
                }
              });
            }
          }
          //check availability belongs to purchased list.
          else if (element.pricemodel === "PAID" || element.pricemodel === "RENTAL") {
            if (this.subscriberid && this.purchases.length > 0) {
              //write purchase check logic.
              this.purchases.forEach((purchase) => {
                if (purchase.availabilityid === element.availabilityid && this.content.objectid === purchase.objectid) {
                  if (!isContentPlayable) {
                    this.isButtonEnable = true;
                    this.buttonStatus = "PLAY NOW";
                    this.content.videoType = "Content";
                    this.content.isDrmContent = true;
                    this.content.playback_details = {
                      packageid: content_details.packageid,
                      availabilityid: element.availabilityid,
                      drmscheme: content_details.drmscheme[0],
                    };
                    isContentPlayable = true;
                  }
                }
              });
            }
          }
          // check availability belongs to free or adbased pricemodel.
          else if (element.pricemodel === "FREE" || element.pricemodel === "ADBASED") {
            if (!isContentPlayable && this.subscriberid) {
              this.isButtonEnable = true;
              this.buttonStatus = "PLAY NOW";
              this.content.videoType = "Content";
              this.content.isDrmContent = true;
              //creating this playback_details for content playback.
              this.content.playback_details = {
                packageid: content_details.packageid,
                availabilityid: element.availabilityid,
                drmscheme: content_details.drmscheme[0],
              };
            }
          }
        });
      } else if (content.hasOwnProperty("trailer") && content.trailer.length > 0) {
        this.isButtonEnable = true;
        if (this.subscriberid) {
          this.buttonStatus = "WATCH TRAILER";
          this.content.isDrmContent = false;
          this.content.videoType = "Trailer";
        } else {
          this.buttonStatus = "LOGIN & WATCH";
        }
      } else {
        this.isButtonEnable = true;
        this.buttonStatus = "COMING SOON";
      }
    },
    buttonHandler() {
      let payload = {};
      if (!this.subscriberid && this.buttonStatus === "PLAY NOW") {
        this.togglePlayer(true);
      } else if (
        !this.subscriberid &&
        (this.buttonStatus === "SUBSCRIBE OR PURCHASE" ||
          this.buttonStatus === "SUBSCRIBE & WATCH" ||
          this.buttonStatus === "PURCHASE & WATCH" ||
          this.buttonStatus === "LOGIN & WATCH")
      ) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      } else if (
        this.subscriberid &&
        (this.buttonStatus === "SUBSCRIBE OR PURCHASE" ||
          this.buttonStatus === "PURCHASE & WATCH" ||
          this.buttonStatus === "SUBSCRIBE & WATCH")
      ) {
        let payload = {};

        //check for filteredAvailabilities length.
        if (this.filteredAvailabilities && this.filteredAvailabilities.length === 1) {
          
          this.filteredAvailabilities.forEach((element) => {
            if (element.pricemodel === "PAID" || element.pricemodel === "RENTAL") {
              payload.listType = "onlyPurchase";
            } else if (element.pricemodel === "PLAN") {
              payload.listType = "onlySubscribe";
            }
          });
        } else if (this.filteredAvailabilities && this.filteredAvailabilities.length > 1) {
          payload.listType = "Subscribe/Purchase";
        }

        payload.availabilities = this.filteredAvailabilities;
        payload.content = this.content;
        eventBus.$emit("subscribePopup", payload);
      } else if ((this.subscriberid && this.buttonStatus === "PLAY NOW") || this.buttonStatus === "WATCH TRAILER") {
        this.togglePlayer(true);
      }
    },
    categoryTabs() {
      if (this.content.category === "MOVIE" && this.content.objecttype !== "BUNDLE") {
        if (this.content.trailer) {
          this.sections = ["Details", "Trailers", "Related"];
        } else {
          this.sections = ["Details", "Related"];
        }
      } else if (this.content.category === "TVSHOW") {
        if (this.content.trailer) {
          this.sections = ["Details", "Episodes", "Trailers", "Related"];
        } else {
          this.sections = ["Details", "Episodes", "Related"];
        }
      } else if (this.content.category === "MUSIC") {
        if (this.content.trailer) {
          this.sections = ["Details", "Songs", "Trailers", "Related"];
        } else {
          this.sections = ["Details", "Songs", "Related"];
        }
      } else if (this.content.category === "TVCHANEL" || this.content.category === "ONLINE") {
        this.sections = ["Details", "Related"];
      } else if (this.content.objecttype === "BUNDLE") {
        if (this.content.trailer) {
          this.sections = ["Details", "Items", "Trailers", "Related"];
        } else {
          this.sections = ["Details", "Items", "Related"];
        }
      }
    },
    togglePlayer(state) {
      let payload = {
        content: this.content,
        state: state,
      };
      eventBus.$emit("togglePlayer", payload);
    },
    closeDetail() {
      eventBus.$emit("off");
      this.closePopup();
    },
    contentLikes(val) {
      this.likeCountDisplay = val;
      eventBus.$emit("like-count-to-mixin", val);
    },
    contentDislikes(val) {
      this.dislikeCountDisplay = val;
      eventBus.$emit("dislike-count-to-mixin", val);
    },
    getPoster() {
      if (this.content.poster && this.content.poster.length) {
        let index = this.content.poster.findIndex((element) => {
          if (this.content.category !== "MUSIC" || this.content.category !== "TVCHANEL") {
            return element.postertype === "LANDSCAPE" && element.quality === "HD";
          }
        });

        if (index > -1) {
          return screen.width > 768
            ? this.content.poster[index].filelist[1].filename
            : this.content.poster[index].filelist[2].filename;
        }
      }
    },
    getMusicPoster() {
      if (this.content.poster && this.content.poster.length) {
        let index = this.content.poster.findIndex((el) => {
          return el.postertype === "SQUARE";
        });
        if (index > -1) {
          return this.content.poster[index].filelist[1].filename;
        }
      }
    },
    getCastCrewData() {
      if (this.content.details) {
        this.content.details.cast.forEach((element) => {
          this.castData.push(element.cast);
        });

        this.content.details.crew.filter((element) => {
          element.role.filter((el) => {
            el === "DIRECTOR";
            this.crewData.push(element.crew);
            // console.log("crewData", this.crewData);
          });
        });
      }
    },
    episodeDetail(payload) {
      
      this.episodeDetailArr = payload;
      this.checkEpisode = true;
    },
    getParentDetail() {
      this.checkEpisode = false;
      this.episodeDetailArr = null;
      this.selectTab(0);
    },
  },
  components: {
    Trailers: () => import(/* webpackChunkName: "trailers" */ "@/components/Templates/trailerCarousel.vue"),
    Episodes: () => import(/* webpackChunkName: "episodes" */ "@/components/Templates/episodesCarousel.vue"),
    Related: () => import(/* webpackChunkName: "related" */ "@/components/Templates/relatedCarousel.vue"),
    Payment: () => import(/* webpackChunkName: "payment" */ "@/components/Popups/Payment/Payment.vue"),
    LikeBtn: () => import(/* webpackChunkName: "likebutton" */ "@/components/SvgImages/LikeBtn.vue"),
    DislikeBtn: () => import(/* webpackChunkName: "dislikebutton" */ "@/components/SvgImages/DislikeBtn.vue"),
    WatchListBtn: () => import(/* webpackChunkName: "watchlistbtn" */ "@/components/watchlist/watchListBtn.vue"),
    Songs: () => import(/* webpackChunkName: "songlist" */ "./songListing.vue"),
    Details: () => import(/* webpackChunkName: "details" */ "@/components/Details/Details.vue"),
    Items: () => import(/* webpackChunkName: "BundleItem" */ "@/components/Templates/BundleItem.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    detailShare: () => import(/* webpackChunkName: "detailshare" */ "@/components/Templates/detailShare.vue"),
  },
  mixins: [Utility, Common],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./detailCard.scss"
</style>
